import React from "react";
import BgeLogo from "../../images/bge_logo.png";
import { Link } from "react-router-dom";
import { CompanyLogo } from "../../constants/common";

const CollapsedLogo = () => {
  return (
    <div style={{display:"flex" ,justifyContent:"end"}} >
      <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
        <img className="logo-light logo-img" width="50px" height="25px" src={CompanyLogo} alt="logo" />
        <img className="logo-dark logo-img" width="50px" src={CompanyLogo} alt="logo" />
        <img className="logo-small logo-img logo-img-small" width="50px" src={CompanyLogo} alt="logo" />
      </Link>
    </div >
  );
};


export default CollapsedLogo;
