import apiService from "../../services/ApiService";
import {
  CLIENT_TICKET_LIST,
  EXPORT_CLIENT_TASK_API,
  CLIENT_PLANT_TASK_VIEW,
  CLIENT_TASK_FILTER_OPTIONS,
  USER_MANAGEMENT_ALL_USERS_API,
  CORRECTIVE_ALL_TASKS_API
} from "../../constants/endpoints";

export const getAllTickets = async (filterParams, payload) => {
  const response = await apiService.post(CLIENT_TICKET_LIST, {
    queryParams: filterParams,
    body:payload
  });
  return response;
};

export const exportClientTask = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_TASK_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const getPvPlantTask = async (taskId) => {
  const response = await apiService.get(CLIENT_PLANT_TASK_VIEW, {
    pathParams: { taskId },
  });
  return response;
};

export const getTaskListFilterOptions = async (filterParams) => {
  const response = await apiService.get(CLIENT_TASK_FILTER_OPTIONS);
  return response;
};

//to get all-users
export const getAllUsers = async () => {
  const response = await apiService.get(USER_MANAGEMENT_ALL_USERS_API);
  return response;
};

export const getAllCorrectivetasks = async () => {
  const response = await apiService.get(CORRECTIVE_ALL_TASKS_API);
  return response;
};