/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import EquipmentAndSpareCompact from "../components/EquipmentAndSpareList";
import { withEquipmentAndSpareProvider, EquipmentAndSpareContext } from "../EquipmentSparesProvider";

const EquipmentAndSpareContainer = (props) => {
  const { loadClientEquipmentList, filterParams, loadClientEquipmentsFilterOptions, pagination, getTableSettings } =
    useContext(EquipmentAndSpareContext);


    const fetchEquipmentsData = async () => {
      await loadClientEquipmentsFilterOptions();
      const settings = await getTableSettings({
        entityType: props?.plantId ? "CLIENT_EQUIPMENT_PLANT" : "CLIENT_EQUIPMENT_AND_SPARES",
      });
      if(settings){
        await loadClientEquipmentList(settings, props.plantId);
      }
    };

  useEffect(() => {
    fetchEquipmentsData(props.plantId);
  }, []);

  useEffect(() => {
    loadClientEquipmentsFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Content>
        <div>
          <EquipmentAndSpareCompact plantId={props.plantId} />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withEquipmentAndSpareProvider(EquipmentAndSpareContainer);
