const RECORD_TYPE = [
  { label: "Add", value: "ADD" },
  { label: "Subtract", value: "SUBTRACT" },
  { label: "Transfer", value: "TRANSFER" },
];
const CAUSE = [
  { label: "Addition", value: "Addition" },
  { label: "Transportation", value: "Transportation" },
  { label: "Installation", value: "Installation" },
  { label: "Uninstall", value: "Uninstall" },
  { label: "Service", value: "Service" },
  { label: "Return", value: "Return" },
  { label: "Other", value: "Other" },
];
const STATUSES = [
  { label: "Less than contractual quantity", value: "less" },
  { label: "Equal to contractual quantity", value: "equal" },
  { label: "Greater than contractual quantity", value: "greater" },
];
export { RECORD_TYPE, CAUSE, STATUSES };
export const TASK = "TASK";


export const EQUIPMENT_FIELDS = [
  {
    label: "Equipment Name",
    value: "assetId",
    field_key: "assetId"
  },
  {
    label: "Plant Name",
    value: "plantId",
    field_key: "plantId"
  },
  {
    label: "Supplier",
    value: "supplier",
    field_name: "Supplier",
  },
  {
    label: "Category",
    value: "category",
    field_key: "category",
  },
  {
    label: "Total Spares",
    value: "quantity",
    field_key: "quantity"
  },
  {
    label: "Contractual Quantity",
    value: "contractualQuantity",
    field_key: "contractualQuantity",
  },
  {
    label: "Is Key Component",
    value: "isKeyComponent",
    field_key: "isKeyComponent",
  },
  {
    label: "Description",
    value: "description",
    field_key: "description",
  },
  {
    label: "Ownership",
    value: "ownership",
    field_key: "ownership",
  },
  {
    label: "Installed Quantity",
    value: "installedQuantity",
    field_key: "installedQuantity",
  },
  {
    label: "Spare Quantity",
    value: "spareQuantity",
    field_key: "spareQuantity",
  },
  {
    label: "External Warehouse Quantity",
    value: "externalWarehouseQuantity",
    field_key: "externalWarehouseQuantity",
  },
  {
    label: "Bge Warehouse Quantity",
    value: "bgeWarehouseQuantity",
    field_key: "bgeWarehouseQuantity",
  },
  {
    label: "Supplier premises Quantity",
    value: "supplierPremisesQuantity",
    field_key: "supplierPremisesQuantity",
  },
  {
    label: "Part Number",
    value: "partNumber",
    field_key: "partNumber",
  },
];
