import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  getAllPlants,
  getPvPlantAPI,
  getPvPlantTasksAPI,
  getPvPlantTask,
  getTaskListFilterOptions,
  getPvplantFilterOptions,
  getPvPlantTotalTasksAPI,
  getAllClients,
  getAllUsers
} from "./PvPlantRepository";
import { format } from "date-fns";
import { getTableViewSettings, updateViewSettings } from "../../repositories/ViewRepository";

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  plant: [],
  plant: [],
  plantManager: [],
};

const initialFilterOptions = {
  statuses: [],
};
const initialPlantOptions = {
  plantManager: [],
  plant: [],
};

export const PvPlantContext = React.createContext();

export const PvPlantProvider = (props) => {
  const [pvPlantsList, setPvPlantsList] = React.useState([]);
  const [pvPlantTaskList, setPvPlantTaskList] = React.useState([]);
  const [pvPlantTotalTasks, setPvPlantTotalTasks] = React.useState([]);
  const [pvPlantColumnSettings, setPvPlantsColumnSettings] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [currentViewPvPlant, setCurrentViewPvPlant] = React.useState(null);
  const [currentViewTask, setCurrentViewTask] = React.useState(null);
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [plantOptions, setPlantOptions] = React.useState(Object.assign({}, initialPlantOptions));

  //Loaders
  const [pvPlantLoading, plState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [pvPlantTaskLoading, pvPlantTaskState] = useBoolean(false);
  const [isLoadingFilters, ilfState] = useBoolean(false);
  const [clientLoading, clState] = useBoolean(false);

  //Close Tab
  const [showFilter, sfState] = useBoolean(false);

  const getpvPlantListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      selectedPlant,
      sortingOrder,
      sortingField,
      plantManager,
      plant,
    } = filterParams;
    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = format(new Date(startDate || null), "yyyy-MM-dd");
    if (resolvedDate) queryParams["taskResolvedDate"] = format(new Date(resolvedDate || null), "yyyy-MM-dd");
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (plantManager) queryParams["plantManager"] = plantManager;
    if (plant) queryParams["plant"] = plant;

    return queryParams;
  };
  const getTableSettings = async (entityType) => {
    try {
      ilfState.on()
      const settings = await getTableViewSettings(entityType);
      setPvPlantsColumnSettings(settings)
      const constructFilterParams = {
        selectedTaskStatus: settings.filters.taskStatus ?? "",
        selectedPlant: settings.filters.taskPlant ?? "",
        selectedAssignedEngineer: settings.filters.taskAssignedEngineer ?? [],
        startDate: settings.filters.taskStartDate ?? null,
        resolvedDate: settings.filters.taskResolvedDate ?? null,
        plantManager: settings.filters.plantManager ?? "",
        plant: settings.filters.plant ?? "",
        sortingOrder: settings.filters.sortingOrder ?? "",
        sortingField: settings.filters.sortingField ?? "",
        searchText: settings.filters.searchText ?? "",
        isArchiveSelected: settings.filters.taskArchived ?? false,
        size: settings.filters.size ?? null
      };
      handleApplyFilter(constructFilterParams)
      return settings;
    } catch (error) {
      throw error;
    } finally {
      ilfState.off()
    }
  };

  const updateTableSettings = async (payload) => {
    try {
      const res = await updateViewSettings(payload);
    } catch (error) {
      throw error;
    }
  };

  const loadPvPLantTaskList = async (plantId, category, status, dashboard) => {
    pvPlantTaskState.on();
    try {
      const filterParams = getpvPlantListFilterParams();
      if (status) {
        filterParams["taskStatus"] = status;
      }
      if (dashboard) {
        filterParams.dashboard = "yes";
      }
      filterParams.category = category;
      const response = await getPvPlantTasksAPI(filterParams, plantId);
      setPvPlantTaskList(response.plantTaskList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      pvPlantTaskState.off();
    }
  };

  const loadAllPlants = async (settings) => {
    plState.on();

    try {
      const filterParams = getpvPlantListFilterParams();
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      const queryParams = Object.assign(filterParams, {
        ...settings.filters,
      });
      const response = await getAllPlants(queryParams, { attributes });
      setPvPlantsList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      plState.off();
    }
  };

  const loadAllUsers = async () => {
    flState.on();
    try {
      const response = await getAllUsers();
      setUserList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  //get all-clients
  const loadAllClients = async () => {
    clState.on();
    try {
      const response = await getAllClients();
      setClientList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      clState.off();
    }
  };
  // VIEW PLANT
  const loadPvPlant = async (plantId) => {
    try {
      const response = await getPvPlantAPI(plantId);
      setCurrentViewPvPlant(response.plant);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  //PVPLAT FILTER OPTION
  const loadPvPlantFilterOptions = async () => {
    try {
      const response = await getPvplantFilterOptions();
      setPlantOptions(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  // VIEW PLANT
  const loadPvPlantTotalTasks = async (plantId) => {
    try {
      const response = await getPvPlantTotalTasksAPI(plantId);
      setPvPlantTotalTasks(response);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // VIEW PLANT
  const loadPvPlantTask = async (taskId) => {
    try {
      const response = await getPvPlantTask(taskId);
      setCurrentViewTask(response.task);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getTaskListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetPvPlantListFilter = () => {
    setFilterParams(Object.assign({}, initialFilterParams));
  };
  //APPLY FILTER
  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.selectedPlant.length != 0 ||
      filterParams.isArchiveSelected ||
      filterParams.searchText ||
      filterParams.startDate ||
      filterParams.resolvedDate ||
      filterParams.plantManager.length != 0 ||
      filterParams.plant.length != 0
    ) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    checkIsFilterApplied,
    isFilterApplied,
    pvPlantsList,
    sfState,
    showFilter,
    pagination,
    pvPlantLoading,
    loadPvPlant,
    loadAllPlants,
    loadPvPlantFilterOptions,
    plantOptions,
    loadPvPLantTaskList,
    loadPvPlantTotalTasks,
    pvPlantTaskLoading,
    currentViewPvPlant,
    handleApplyFilter,
    resetPvPlantListFilter,
    pvPlantTaskList,
    loadPvPlantTask,
    currentViewTask,
    filtersLoading,
    filterOptions,
    filterParams,
    pvPlantTotalTasks,
    loadFilterOptions,
    pvPlantColumnSettings,
    setPvPlantsColumnSettings,
    getTableSettings,
    updateTableSettings,
    isLoadingFilters,
    loadAllClients,
    loadAllUsers,
    userList,
    clientList,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PvPlantContext.Provider value={mContext}>{props.children}</PvPlantContext.Provider>;
};
export const withPvPlantProvider = (Container, containerProps) => (props) =>
(
  <PvPlantProvider>
    <Container {...containerProps} {...props} />
  </PvPlantProvider>
);
