/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import CorrectiveTaskListCompact from "../components/CorrectiveTaskList";
import { useLocation,useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketManagementContext } from "../../ticket_management/TicketManagementProvider";

const CorrectiveTaskContainer = (props) => {

  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const { loadTicketList, getTableSettings, correctiveList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
const {loadAllUsers,loadAllCorrectiveTasks} = useContext(TicketManagementContext)

  const plantId = props.plantId;

  const fetchCorrectiveTasksData = async () => {
    const settings = await getTableSettings({
      entityType: "CLIENT_CORRECTIVE_PLANT",
    });
    if (settings) {
      if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
        const queryParams = new URLSearchParams(location);
        if (queryParams.has("status")) {
          queryParams.delete("status");
          history.replace({
            search: queryParams.toString(),
          });
        }
        if (plantId && !status) {
        await loadTicketList(settings,"corrective",{plantId:plantId});
        }
      }
      await loadFilterOptions()
      if (status && !dashboardFilterStatus) {
        loadTicketList(settings,"corrective",{plantId:plantId,status})
      }
    }
  };

  useEffect(() => {

    fetchCorrectiveTasksData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
    loadAllCorrectiveTasks()
  }, [])

  return (
    <React.Fragment>
      <Content style={{ padding: 0 }} className="p-0">
        <CorrectiveTaskListCompact plantId={plantId} ticketsList={correctiveList}/>
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(CorrectiveTaskContainer);