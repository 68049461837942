import React, { useEffect } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import useBoolean from "../../../hooks/useBoolean";
import { TicketManagementContext } from "../TicketManagementProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TICKET_FILTERS } from "../../../constants/PreferenceKeys";

const TicketListFilter = (props) => {
  const ticketManagementContext = React.useContext(TicketManagementContext);
  const { filterOptions ,viewingPage} = props;
  const {
    filterParams,
    handleApplyFilter,
    showFilter,
    sfState,
    setDashboardFilterStatus,
    checkIsFilterApplied,
    statusFilterData,
  } = ticketManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    const taskSettings = ticketManagementContext.tasksColumnSettings;
    const settings = {
      ...taskSettings,
      filters: {
        ...(taskSettings.filters.size && { size: taskSettings.filters.size }),
        ...(taskSettings.filters.page && {page: taskSettings.filters.page}),
        ...(taskSettings.filters.sortingField && { sortingField: taskSettings.filters.sortingField }),
        ...(taskSettings.filters.sortingOrder && { sortingOrder: taskSettings.filters.sortingOrder }),
      },
    };
    handleApplyFilter(selectedFilter);
    ticketManagementContext.setTasksColumnSettings(settings)
    ticketManagementContext.updateTableSettings(settings);
    ticketManagementContext.resetClientListFilter();
    ticketManagementContext.loadTicketList({
      ...settings,
      filters: {
        ...settings.filters,
        taskStatus: "",
        taskAssignedEngineer: [],
        taskPlant:  "",
        taskArchived: false,
        taskStartDate: "",
        taskResolvedDate: "",
        table: "",
        taskSLA: ""
      },
    },viewingPage);
  };

  const handleApply = () => {
    const settings = {
      ...ticketManagementContext.tasksColumnSettings,
      filters: {
        ...ticketManagementContext.tasksColumnSettings.filters,
        taskStatus: selectedFilter.selectedTaskStatus ?? "",
        taskAssignedEngineer : selectedFilter.selectedAssignedEngineer ?? [],
        taskPlant:selectedFilter.selectedPlant ?? [],
        taskArchived: selectedFilter.isArchiveSelected ?? "",
        taskStartDate: selectedFilter.startDate ?? "",
        taskResolvedDate: selectedFilter.resolvedDate ?? "",
        table: selectedFilter.table ?? "",
        taskSLA: selectedFilter.selectedSLA ?? "",
      },
    }
    handleApplyFilter(selectedFilter);
    setDashboardFilterStatus.on();
    ticketManagementContext.updateTableSettings(settings)
    ticketManagementContext.setTasksColumnSettings(settings)
    checkIsFilterApplied();
    sfState.off();
    ticketManagementContext.loadTicketList(settings,viewingPage);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedTaskStatus: statusFilter });
  };

  const handleAssignedFilterSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedAssignedEngineer: assignedFilter });
  };
  const handlePlantSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedPlant: assignedFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const handleStartDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ startDate: "" });
    } else {
      handleFilterSelection({ startDate: selectedOption });
    }
  };

  const handleResolvedDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ resolvedDate: "" });
    } else {
      handleFilterSelection({ resolvedDate: selectedOption });
    }
  };

  const saveFilter = () => {
    localStorage.setItem(TICKET_FILTERS, JSON.stringify(filterParams));
  };

  const statusSelectedFilter = selectedFilter.selectedTaskStatus && selectedFilter.selectedTaskStatus.map((item, key) => {
    return filterOptions.taskStatuses?.[filterOptions.taskStatuses?.findIndex((status) => status.value === item)];
  });

  const assignedEngineerSelectedFilter = selectedFilter.selectedAssignedEngineer && selectedFilter.selectedAssignedEngineer.map((item, key) => {
    return filterOptions.assignedEngineer?.[
      filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
    ];
  });

  const plantSelectedFilter =selectedFilter.selectedPlant && selectedFilter.selectedPlant.map((item, key) => {
    return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={filterOptions.taskStatuses}
                  placeholder="Any Status"
                  defaultValue={{ label: statusFilterData, value: statusFilterData }}
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Assigned Engineer</label>
                <RSelect
                  options={filterOptions.assignedEngineer}
                  placeholder="Any Engineer"
                  onChange={handleAssignedFilterSelection}
                  isMulti
                  value={assignedEngineerSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Plant Name</label>
                <RSelect
                  options={filterOptions.plants}
                  placeholder="Any Plant"
                  onChange={handlePlantSelection}
                  isMulti
                  value={plantSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Start Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.startDate || null}
                  onChange={(date) => handleStartDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Resolved Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.resolvedDate || null}
                  onChange={(date) => handleResolvedDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox" /* style={{ 'z-index': '-1' }} */>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col size="12" className={"d-flex justify-content-between"}>
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default TicketListFilter;
