import React, { useContext, useEffect } from "react";
import PrivateRoute from "./route/PrivateRoute";
import Layout from "./layout/Index";
import "./custom.css";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import { AuthContext, withAuthProvider } from "./providers/AuthContext.provider";
import { Spinner } from "reactstrap";
import { CLIENT_LOGIN_PAGE, CLIENT_FORGOT_PASSWORD_PAGE, CLIENT_RESET_PASSWORD_PAGE } from "./constants/routes";
import { withMultiProviders } from "./providers/withMultiProviders";
import { withUserManagementProvider } from "./pages/user_management/UserManagementProvider";
import { withGlobalSearchProvider } from "./providers/GlobalSearchProvider";
import { withTicketManagementProvider } from "./pages/ticket_management/TicketManagementProvider";

import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";

const App = () => {
  const authContext = useContext(AuthContext);
  const isStatusDetermined = authContext.isDetermined;

  useEffect(() => {
    authContext.lookupUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isStatusDetermined) return <Spinner />;

  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={CLIENT_LOGIN_PAGE} component={Login}></Route>
      <Route exact path={CLIENT_FORGOT_PASSWORD_PAGE} component={ForgotPassword}></Route>
      <Route exact path={CLIENT_RESET_PASSWORD_PAGE} component={ResetPassword}></Route>

      {/*Error Pages*/}
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-modern`} component={Error504Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-modern`} component={Error404Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-classic`} component={Error504Classic}></Route>

      {/*Main Routes*/}
      <PrivateRoute exact path="" component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(withMultiProviders([withAuthProvider,
  withUserManagementProvider,
  withGlobalSearchProvider, withTicketManagementProvider], App));
