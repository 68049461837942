/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import TicketList from "../components/TicketList";
import { selectedMaintenanceTab } from "../../../constants/common";
import { useLocation } from "react-router-dom";

const TicketManagementContainer = (props) => {
  const { loadTicketList ,getTableSettings,loadAllUsers, loadAllCorrectiveTasks} = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions } = useContext(TicketManagementContext);
  const { pathname } = useLocation();

  const selectedTab = pathname.split("/");

  const tabName = selectedMaintenanceTab[selectedTab[selectedTab.length - 1]];

  const category = (categoryName) => {
    switch (categoryName) {
      case "Corrective Maintenance Tickets":
        return "corrective";
      case "Preventative Maintenance Tickets":
        return "preventative";
      case "Remedial Maintenance Tickets":
        return "remedial";
      default:
        return "N/A";
    }
  };
  const fetchTicketsData = async () => {
    const settings = await getTableSettings({
      entityType: "CLIENT_CORRECTIVE_MAINTENANCE",
    });
    if (settings) {
        await loadTicketList(settings,category(tabName));        
      }
      await loadFilterOptions()
    }

  useEffect(() => {
    fetchTicketsData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
    loadAllUsers()
    loadAllCorrectiveTasks()
  }, []);
  return (
    <React.Fragment>
      <Head title="Pv-Plant" />
      <Content>
        <TicketList />
      </Content>
    </React.Fragment>
  );
};

export default withTicketManagementProvider(TicketManagementContainer);
