import React, { useContext } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LoginContext, withLoginProvider } from "./providers/LoginContext.provider";
import { AuthContext } from "../../providers/AuthContext.provider";
import { CLIENT_FORGOT_PASSWORD_PAGE } from "../../constants/routes";
import { CompanyLogo } from "../../constants/common";

const Login = () => {
  const authContext = useContext(AuthContext);
  const loginContext = useContext(LoginContext);

  const { loading, setLoading } = loginContext;
  const { passState, setPassState } = loginContext;
  const { errorVal, setError } = loginContext;

  const onFormSubmit = async (formData) => {
    setLoading(true);
    try {
      await authContext.onLogin(formData);
    } catch (e) {
      setError(e.toString());
    } finally {
      setLoading(false);
    }
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  return (
    <React.Fragment>
      <Head title="Brighter App | BGE Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={CompanyLogo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={CompanyLogo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Login</BlockTitle>
                <BlockDes>
                  <p>Login using your email and password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    {...register("email", { required: "Email is required" })}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                  {errors.email?.message && <span className="invalid">{errors.email?.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <Link className="link link-primary link-sm" to={CLIENT_FORGOT_PASSWORD_PAGE}>
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    {...register("password", { required: "Password is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.password?.message && <span className="invalid">{errors.password?.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <button
                  style={{
                    backgroundColor: "rgb(0, 110, 97)",
                    border: " 1px solid rgb(0, 110, 97)",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                  type="submit"
                  size="lg"
                  className="btn-block"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Login"}
                </button>
              </FormGroup>
            </Form>
            {/* {renderFormCardFooter()} */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default withLoginProvider(Login);
