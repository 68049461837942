import apiService from "../../services/ApiService";
import {

    STOCK_CLIENT_TRANSACTION_LIST_API,
    EXPORT_CLIENT_EQUIPMENT_SPARE_API,
    CLIENT_EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API,
    CLIENT_EQUIPMENT_SPARE_VIEW_API,
    CLIENT_EQUIPMENT_SPARE_LIST_API
} from '../../constants/endpoints';

export const getClientEquipmentsList = async (filterparams, payload) => {
    const response = await apiService.post(CLIENT_EQUIPMENT_SPARE_LIST_API, {
      queryParams: filterparams,
      body: payload,
    });
    return response;
  };

export const getClientEquipmentFilterOptions = async filterParams => {
    const response = await apiService.get(CLIENT_EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API)
    return response
}

export const getClientEquipmentAPI = async equipmentId => {
    const response = await apiService.get(CLIENT_EQUIPMENT_SPARE_VIEW_API, {
        pathParams: { equipmentId }
    })
    return response
}

export const exportClientEquipmentAPI = async (filterParams) => {
    const response = await apiService.get(EXPORT_CLIENT_EQUIPMENT_SPARE_API, {
        queryParams: filterParams,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
        }
    })
    return response
}

export const getClientStockTransactionAPI = async queryParams => {
    const response = await apiService.get(STOCK_CLIENT_TRANSACTION_LIST_API, {
        queryParams
    })
    return response
}

