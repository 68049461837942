import React, { useEffect } from "react";
import {FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { PvPlantContext } from "../PvPlantProvider";

import "react-datepicker/dist/react-datepicker.css";
import { TICKET_FILTERS } from "../../../constants/PreferenceKeys";

const PvplantFilter = (props) => {
  const pvPlantContext = React.useContext(PvPlantContext);

  const { filterParams, handleApplyFilter, sfState, showFilter, plantOptions } = pvPlantContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

    const handleReset = () => {
      const pvPlantSettings = pvPlantContext.pvPlantColumnSettings;
      const settings = {
        ...pvPlantSettings,
        filters: {
          ...(pvPlantSettings.filters.size && { size: pvPlantSettings.filters.size }),
          ...(pvPlantSettings.filters.sortingField && { sortingField: pvPlantSettings.filters.sortingField }),
          ...(pvPlantSettings.filters.sortingOrder && { sortingOrder: pvPlantSettings.filters.sortingOrder }),
        },
      };
      handleApplyFilter(selectedFilter);
      pvPlantContext.setPvPlantsColumnSettings(settings)
      pvPlantContext.updateTableSettings(settings);
      pvPlantContext.resetPvPlantListFilter();
      pvPlantContext.loadAllPlants({
        ...settings,
        filters:{...settings.filters,taskStatus: "",
        taskArchived: false,
        plantManager: "",},
      });
    };

    const handleApply = () => {
      const settings = {
        ...pvPlantContext.pvPlantColumnSettings,
        filters: {
          ...pvPlantContext.pvPlantColumnSettings.filters,
          taskStatus: selectedFilter.selectedTaskStatus ?? "",
          taskArchived: selectedFilter.isArchiveSelected ?? "",
          plantManager: selectedFilter.plantManager ?? "",
        },
      };
      handleApplyFilter(selectedFilter);
    pvPlantContext.updateTableSettings(settings);
    pvPlantContext.setPvPlantsColumnSettings(settings)
    sfState.off();
    pvPlantContext.loadAllPlants(settings)
  };
  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedTaskStatus: statusFilter });
  };

  const handlePlantManagerSelection = (selectedOption) => {
    const plantManagerFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ plantManager: plantManagerFilter });
  };
  const handlePlantSelection = (selectedOption) => {
    const plantFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ plant: plantFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const statusSelectedFilter = selectedFilter.selectedTaskStatus && selectedFilter.selectedTaskStatus.map((item, key) => {
    return plantOptions.plantStatuses?.[plantOptions.plantStatuses?.findIndex((status) => status.value === item)];
  });

  const plantManagerSelectedFilter = selectedFilter.plantManager && selectedFilter.plantManager.map((item, key) => {
    return plantOptions.plantManager?.[plantOptions.plantManager?.findIndex((data) => data.value === item)];
  });

  const plantSelectedFilter = selectedFilter.plant && selectedFilter.plant.map((item, key) => {
    return plantOptions.plant?.[plantOptions.plant?.findIndex((data) => data.value === item)];
  }); 

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={plantOptions.plantStatuses}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Plant Manager</label>
                <RSelect
                  options={plantOptions.plantManager}
                  placeholder="Plant Manager"
                  isMulti
                  onChange={handlePlantManagerSelection}
                  value={plantManagerSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Plant</label>
                <RSelect
                  isMulti
                  options={plantOptions.plant}
                  placeholder="Plant"
                  onChange={handlePlantSelection}
                  value={plantSelectedFilter}
                />
              </FormGroup>
            </Col>

            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className={"d-flex justify-content-between"} size="12">
              <Button
                href="#reset"
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PvplantFilter;
